



































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class WindChimeWork extends Vue {
  @Prop() jsonData!: any;

  public mounted() {
    console.log(this.jsonData);
  }
}
